@import "~bootstrap/scss/bootstrap";

:root {
  --bs-body-font-size: 0.95rem;
}

// $content-width: 627px;
$content-width: 680px;

//$box-bg: #334041;
//$box-bg: lighten(#d3d5d4, 15%);
$box-bg: lighten(#758c9b, 30%);
$box-bg: #8cd8f2;

//$color: rgba(244, 244, 246, 1);
// $color: #334041;
$color: black;
// $color: white;

$orange: #f5835d;

$blue: lighten(#00566e, 5%);

@font-face {
  font-family: "Lato";
  src: local("Lato"), url(./fonts/Lato/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Crimson Text";
  src: local("Crimson Text"), url(./fonts/Crimson_Text/CrimsonText-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Work Sans";
  src: local("Work Sans"), url(./fonts/Work_Sans/static/WorkSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./fonts/Inter/static/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "IBM Plex Serif";
  src: local("IBM Plex Serif"), url(./fonts/IBM_Plex_Serif/IBMPlexSerif-Regular.ttf) format("truetype");
}

@keyframes bg-kf-up {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-400px);
    // transform: translateY(0px) scale(1.2) rotate(0deg);
  }
}

@keyframes bg-kf-down {
  0% {
    background-position-y: 0px;
  }
  100% {
    background-position-y: 400px;
  }
}

body {
  margin: 0;
  /*background: #141514;*/
  // background: rgba(244, 244, 246, 1);
  // background: url(https://dasdorner.com/wp-content/themes/dasdorner-2021/inc/img/pattern-landscape-beige.svg);
  // background-size: contain;
  background-repeat: none;
  // background-color: #dfd9ca;
  // https://www.heropatterns.com/
  /*background: #3e2821;*/
  /*background: darken(#e9dad6, 15%);*/
  color: $color;
  // font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  //   "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  // background-image: url("https://66.media.tumblr.com/783c9e712096a22e13da9b8ea7b8328a/tumblr_p5chz9iyLU1s9vky5o1_1280.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  // animation-fill-mode: forwards;
  overflow-x: hidden;

  text-align: left;

  // background-image: url("https://66.media.tumblr.com/d9b98ad2edd17bd20e0da0a674faf255/tumblr_oz9y35SW0h1uvus64o1_1280.jpg");
  background-size: cover;
  // background-color: $box-bg;
  background: white;

  font-family: "Inter";
  // font-size: 18px;
  // font-weight: 600;
    // letter-spacing: -0.5px;
}

#root::before {
  // content: "";

  opacity: 1;

  display: block;
  position: absolute;
  width: 100%;
  height: calc(100vh + 200%);
  top: 0;
  left: 0;

  background-color: black;
  opacity: 0.3;

  //background-repeat: none;
  // background-color: #dfd9ca;
  // background-color: #d3d5d4;
  // background-color: $box-bg;
  z-index: -1;

  animation-name: bg-kf-up;
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-direction: forwards;
  background-size: 400px;
  animation-timing-function: linear;
}

h1,
h2,
h3,
h4,
h5,
h6,
.slogan {
  letter-spacing: 0.05em;
  font-family: "Lato", sans-serif;
  font-weight: 900;

  &:not(.cover-img-title) {
    text-transform: uppercase;  
  }
  // font-family: "IBM Plex Serif", serif;
}

// .ibm-plex {
//   font-family: "IBM Plex Serif", serif !important;
// }

h1, .slogan {
  font-family: "Lato";
  position: relative;
  z-index: 2;
  font-weight: 500;
  line-height: 1.2;
}

// h1::before {
//   content: "";

//   width: calc(100% - 30px);
//   height: 6px;
//   left: 15px;
//   opacity: 0.7;
//   bottom: 4px;
//   background: $orange;
//   position: absolute;
//   z-index: -1;
// }

h1.in-box {
  font-size: 1.85rem;
}

h1, .slogan {
  position: relative;
  z-index: 2;
}

h1:not(.name):not(.in-box)::before, .slogan::before {
  content: "";

  width: 50px;
  height: 3px;
  right: 15px;
  bottom: -6px;
  background: $orange;
  position: absolute;
  z-index: 1;

  position: absolute;
}

h2 {
  position: relative;
  z-index: 2;
  margin-bottom: 1em;
}

// h2::before {
//   content: "";
//   width: 25px;
//   height: 1.5px;
//   left: 7.5px;
//   bottom: -6px;
//   background: $color;
//   position: absolute;
//   z-index: 1;
// }

h2 {
  // text-transform: uppercase;
  // font-size: 1.65rem;
  font-size: calc(1.15rem + 0.5vw) !important;
}


a:not(.btn):not(.nav-link), .btn-link {
  color: $blue !important; 
  text-decoration: none !important;
}
  
a:not(.btn):not(.nav-link):hover, .btn-link:hover {
  text-decoration: underline !important;
}

.nav {
  width: 340px !important;
  .nav-link {
    font-size: 1.35rem;
    color: $color;

    &.active {
      //background: lighten($orange, 15%);
      // background: #747a70;
      // background: $orange;
      color: $orange;
    }
  }
}


.mobile-nav-container {
  position: fixed;
  top: calc(75px + 1.5vw);
  left: 0px;
  background: white;
  width: 100%;
  padding: 4vw;
  bottom: 0;
  border-bottom: 2px solid black;
  border-top: 3px solid black;
  text-align: right;

    .nav-link {
    padding-top: 15px;
    font-size: calc(1.275rem + 1.5vw);
   }

   // .nav-link:first-child {
   //  border-top: 1px solid black;
   // }

   .footer-nav-link {
    font-size: calc(0.725rem + 1.5vw);
   }
}

hr {
  margin: 1rem 0;
  border-top: none;
}

*:not(.btn) {
  border-radius: 0 !important;
}

.container {
  // background: url("./img/bg.jpg");
  // background: #212121;
  // border-left: 10px solid #8fa7ad;
  // border-right: 10px solid #8fa7ad;
  max-width: 1000px;
  // background: rgba(#323a40, 0.95);
  // background: rgba(#22272d, 1.0)
  // background-color: $box-bg;
  background-color: white;
  

  // animation-name: bg-kf-down;
  // animation-duration: 30s;
  // animation-iteration-count: infinite;
  // animation-direction: forwards;
  // background-size: 400px;
  // animation-timing-function: linear;
  max-width: 100vw;
}

.logo-container {
  // background: lighten($orange, 5%);
  // color: white;
}

$highlight-border: 10px solid #f8b949;

.highlight-border-l {
  // border-left: $highlight-border;
}

.highlight-border-r {
  // border-right: $highlight-border;
}

.highlight-border-t {
  // border-top: $highlight-border;
}

.highlight-border-b {
  // border-bottom: $highlight-border;
}

.sep-box {
  // background: rgba(247, 185, 73, 0.75) !important;
  // background: rgba($orange, .75);
  // background: rgba(#8fa7ad, 0.75);
  background: $color;

  // background: transparent;
  // border-top: 5px solid $orange;
  // border-bottom: 5px solid $orange;
  // height: 7.5px;
  height: 2px;
  // width: $content-width;
}

.contact-form {
  width: $content-width;
}

.cover-img {
  height: 250px;
  // height: calc(100vh - 135px);
  height: calc(100vh - 150px);
  object-fit: cover;
  object-position: bottom;
  z-index: 1;
}

.cover-img-container {
  position: fixed;
  left: 0;
  // top: 108px;
  top: 88px;
  width: 100vw;

  > .cover-img-title {
    font-size: calc(1.275rem + 1.5vw);
    position: absolute;
    right: 100px;
    color: white;
    bottom: 10%;
    z-index: 3;

    @media screen and (max-width: 1000px) { 
      bottom: auto;
      top: 50px;
      right: 4%;
    }
  }

  &::after {
    content: ""; // ::before and ::after both require content
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #282832;

    background: rgb(21,21,23);
    background: linear-gradient(180deg, rgba(21,21,23,0.8) 0%, rgba(0,0,0,0.95) 100%);
    // opacity: 0.9;
    z-index: 2;
    // filter: blur(10px);
  }
}

.portfolio-img {
  width: $content-width;
  max-height: 300px;
  object-fit: contain;
}

p {
  margin-bottom: 0;
}

.box {
  border: 1px solid $color;
  padding: 10px;
  /*box-shadow: green 0px 8px 24px;*/
  /*box-shadow: rgba(244, 244, 246, 0.1) 0px 10px 50px;*/
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
// }

header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  border-bottom: 1px solid $color;
}

header h1 {
  margin-bottom: 0;
}

.title-box {
  padding-top: 0px;
  padding-right: 10px;
  padding-left: 3vw;
  text-align: right;
  font-size: 1.5vw;

  h1 {
    @media screen and (max-width: 600px) {
      font-size: 30px !important;
    }
  }

  img {
    @media screen and (max-width: 650px) {
      width: 250px;
      object-fit: contain;
    }
  }
}

.logo {
  /*padding: 10px;*/
}

.content {
  // width: $content-width;

  width: 100%;
}


.content-card {
  position: relative;
  width: 65vw;
  max-width: 1100px;
  margin-top: 30px;
  margin-left: 30px;
  // top: 328px;
  z-index: 2;
  background: #ffffffed;
  // padding: 60px;
  padding: 40px;
  // left: 50px;
  border-radius: 20px !important;

  overflow: hidden;

  &.home {
    margin-top: 30px;
  }

  &.portfolio {
    // margin-top: 140px;
  }

  &.contact {
    margin-bottom: 100px;
    // margin-top: 200px;
    // margin-bottom: 50px;
  }

  &.imprint, &.dataprotection {
    p {
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 1000px) {
    background: white;
  }

  .content {
    @media screen and (min-width: 1000px) {
      width: calc(100vw - 500px);
      max-width: 980px;
    }
  }

  @media screen and (max-width: 999px) {
    width: 94vw !important;
    margin-left: 3vw !important;
    margin-right: 3vw !important;
    text-align: left !important;
  }

  &.first  {

    margin-top: 140px;
    @media screen and (max-width: 1000px) {
      margin-top: 220px;
    }  

    &.home {
      @media screen and (max-width: 1000px) {
        margin-top: 300px;
      }  
    }

    // &.contact {
    //   @media screen and (max-width: 1000px) {
    //     margin-top: 170px;
    //   }  
    // }
     
  }


}

.footer {
  position: fixed;
  z-index: 2;
  width: 100vw;
  left: 0;
  bottom: 0;
  border-top: 1px solid $color;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.content-card {
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.content-card h2, .content-card h1 {
  animation: fadeInUp 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.content-card:not(.imprint):not(.dataprotection) p,
.content-card:not(.imprint):not(.dataprotection) ul {
  animation: fadeInUp 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.content-card.home strong {
  animation: fadeInUp 1s;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.content-card svg,
.content-card img {
  animation: fadeInLeft 1s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.cover-img-title {
  animation: fadeInLeft 1s;
  animation-fill-mode: forwards;
  // opacity: 0;
}

.content-card.contact > form div {
  animation: fadeInLeft 1s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.content-card.contact > form button {
  animation: fadeInUp 1s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
}


.footer-legal-links {
  a {
    color: $color !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
    font-family: "Work Sans", sans-serif;

    &.active {
      color: $orange !important;
    }
  }
}

.disabled {
  // opacity: 0.65 !important;
}



.highlight-text {
    display: inline-block;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(45deg, #21718c, $color 25%, $orange 45%, $color 65%, $color 75%, $color);
    font-weight: 900;
    font-size: calc(1.45rem + 0.5vw) !important;
    transform: translateZ(0);
    padding-bottom: 0.05em;
    margin-bottom: -0.05em;
    background-color: var(--text-gradient-background-color);
    background-position-x: 0;
    background-size: 225% 100%;
    background-repeat: no-repeat;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}